module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["en","de"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CinchBuild","short_name":"CB","description":"CB","start_url":"/","background_color":"#FF6240","theme_color":"#FF6240","display":"minimal-ui","icon":"/app/src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f48162b0519631b7257816db3152053"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
